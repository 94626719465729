import WithAddonBeforeInfoPanel from '@weights/WithAddonBeforeInfoPanel'

import { GUARANTEE_TYPE_MAPPING } from '@constant/enum'

import { UTILIZATION_AMOUNT_COLUMN, GUARANTEE_PANEL_COLUMNS_FACTORY } from '../config'

export default {
    name: 'GuaranteePanel',
    props: {
        utilizationInfo: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        guaranteeVO() {
            return this.utilizationInfo.guaranteeVO
        },
        // 保函类型
        guaranteeType() {
            return this.guaranteeVO?.guaranteeType
        },
        // 面板标题
        panelTitle() {
            if (!this.guaranteeType) return
            return `保函（${GUARANTEE_TYPE_MAPPING[this.guaranteeType]}）`
        },
        columns() {
            if (!this.guaranteeType) return []
            return GUARANTEE_PANEL_COLUMNS_FACTORY[this.guaranteeType] || []
        }
    },
    render(h) {
        if (!this.guaranteeVO) {
            return null
        }

        return h(WithAddonBeforeInfoPanel, {
            class: 'has-margin-top',
            props: {
                itemColumn: 2,
                columns: this.columns,
                title: this.panelTitle,
                dataSource: this.utilizationInfo,
                addonBeforeColumns: [UTILIZATION_AMOUNT_COLUMN]
            }
        })
    }
}
